export const environment = {
  production: true,
  baseUrl: 'dev.ministrary.com',
  supabaseUrl: 'https://neutorsfpfwnnqewxpjw.supabase.co',
  supabaseKey:
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im5ldXRvcnNmcGZ3bm5xZXd4cGp3Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2Nzg4ODAwNTQsImV4cCI6MTk5NDQ1NjA1NH0.sTzPXS07D90KgFvIKo1-wuxaEi7_fV-lKe1Ce9AHc_c',
  apiUrl: 'https://dev-api.ministrary.com',
  googleAnalyticsId: 'G-1KZ9XSS53X',
  helpUrl: 'https://ministrary.tawk.help',
  whatsapp: {
    instanceID: '3D05C413C95300C80933D6E96DCD545E',
    token: '2DF33B2F940F80396E17E9AB',
    securityToken: 'F437190eee4e14d6eb152fbb844caab68S'
  },
  testUserKey: 'yvb7oEbL6S8q8YyDgVAB'
};
